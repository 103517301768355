/* react-calendar overrides */
.react-calendar,
.react-calendar-active {
  font-family: "Montserrat", "Roboto", "sans-serif";
}
.react-calendar__tile--active {
  background-color: #006edc;
  color: white !important;
}
.react-calendar-active .react-calendar__tile {
  color: green;
  font-weight: 700;
}
.react-calendar__tile:disabled {
  background-color: transparent;
  color: black;
}
