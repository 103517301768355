.container .MuiFormControl-root {
  margin-left: calc(50% - 200px);
  margin-top: 2em;
  border-radius: 10px;
}

#valueSet .MuiFormControl-root {
  background-color: #0071bc;
}

.MuiFormControl-root em {
  font-style: normal;
  font-family: "Montserrat", "Roboto", "sans-serif";
  font-size: 1.5em;
}

.MuiInputBase-root {
  margin: 0 auto;
  box-shadow: 0px 1px 13px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 1px 13px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 13px -1px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 400px;
}

.MuiList-root .MuiMenuItem-root {
  font-family: "Montserrat", "Roboto", "sans-serif";
}

#valueSet .menuItemContent {
  text-align: left;
  font-style: normal;
  font-family: "Montserrat", "Roboto", "sans-serif";
  font-size: 1.5em;
  color: white;
}

#valueSet .MuiSvgIcon-root {
  color: white;
}

.menuItemContent {
  font-style: normal;
  font-family: "Montserrat", "Roboto", "sans-serif";
  font-size: 1.5em;
}

.menuItemContent p:first-child {
  font-weight: bold;
  margin-bottom: 0.4em;
}
