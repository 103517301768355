@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.md h1 {
  @apply font-bold text-center text-lg mb-5 mt-5;
}

.md h2 {
  @apply font-bold text-center text-base mb-5 mt-5;
}

.md h3 {
  @apply font-bold text-base mb-5 mt-5;
}

.md ul {
  @apply list-disc ml-5 mr-5;
}

.md ol {
  @apply list-decimal ml-5 mr-5;
}

.md p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

button:disabled {
  cursor: default;
}

/* Swiper js overrides */
.swiper-button-prev,
.swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-color: #fff;
  background-size: 17px 23px;
  padding: 24px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.swiper-button-next,
.swiper-button-prev {
  width: 68px !important;
  height: 68px !important;
}
.swiper-button-prev {
  background-image: url("img/left-arrow.svg");
}
.swiper-button-next {
  background-image: url("img/right-arrow.svg");
}
.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

/* Toggle Switch */
input:checked ~ .dot {
  transform: translateX(100%);
}

/* File Upload */
.file-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-upload + label {
  display: inline-block;
}

.file-upload + label {
  cursor: pointer; /* "hand" cursor */
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

.SDYZEU-keyboard-shortcuts-dialog-view {
  display: none !important;
}